

// @mui material components
import * as React from 'react';
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import 'app.css'
import { Password } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { addEnrollStudent } from '../../../src/redux/slices/EnrollSlice';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { API_Host } from 'Api_utils';

import Swal from "sweetalert2";


function EnrollStudent() {

  const [language, setLanguage] = React.useState('');
  const [course,setCourses] = React.useState('');
  const [freelancer,setFreenLancer] = React.useState('');
  const [role,setRole] = React.useState('');
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const history = useHistory ();


  // enroll fields state
  const [enrollData, setEnrollData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    phone: "",
    language: "",
    course: "",
    // rewardPoints: "",
    // freelancer: ""
  });

  // const handleEnroll = (e) => {
  //   e.preventDefault();
  //   axios.post(`${API_Host}/add-users`,enrollData)
  //   .then(res => {
  //     dispatch(addEnrollStudent(res.data))
  //     console.log("Enroll Student Successfully Added",res.data);
  //     history.push('/view-student')
  //   })
  //   .catch(err =>console.log(err) )
   
  // }

  const handleEnroll = async (e) => {
    e.preventDefault();
const validation = await validate()
    if (!validation) {
return
    }
      console.log("Submitting data:", enrollData);

  console.log("handleEnroll triggered");
      axios.post(`${API_Host}/add-users`, enrollData)
        .then(res => {
          dispatch(addEnrollStudent(res.data));
          console.log("Enroll Student Successfully Added", res.data);
          Swal.fire({
            title: "Student Added Successfully!",
            text: "Student has been added.",
            icon: "success",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          });
          history.push('/view-student');
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.message) {
            // If the server returns an error message, set it to the error state
            setErrors(prevErrors => ({
              ...prevErrors,
              email: err.response.data.message  // Assuming the error message is in response.data.message
            }));
          } else {
            console.log(err);
          }
        });
    
   
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEnrollData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const newErrors = { ...errors };
    if (value.trim() === '') {
      newErrors[name] = ``; 
    } else {
      delete newErrors[name]; 
    }
    
    // Update the error state
    setErrors(newErrors);
  };


  //   to fetch Coursename state
const [courseName,setCourseName] = useState([]);



React.useEffect(() => {
    const fetchCourseName = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-course`);
        setCourseName(response.data.data.map(course => course.courseName));
      } catch (error) {
        console.error("Error fetching coursename:", error);
      }
    };

    fetchCourseName();
  }, []);

 

  const handleFreeLancer = (event) => {
    const selectedFreeLancer = event.target.value
    setFreenLancer(selectedFreeLancer);
    setEnrollData((prevData) => ({
      ...prevData,
      freelancer: selectedFreeLancer,
    }));
  }

  // const handleRole = (event) => {
  //   const selectedRole = event.target.value
  //   setRole(selectedRole);
  //   setEnrollData((prevData) => ({
  //     ...prevData,
  //     role: selectedRole,
  //   }));
  // }

  const handleRole = (event) => {
    const selectedRole = event.target.value;
    setRole(selectedRole);
  
    // Update enrollData to reflect the selected role
    setEnrollData((prevData) => ({
      ...prevData,
      role: selectedRole,
    }));
  
    // Revalidate the form and clear errors if the selection is valid
    const newErrors = { ...errors };
    if (selectedRole) {
      delete newErrors.role; // Clear the error if a valid role is selected
    }
    setErrors(newErrors);
  };
  
  const handleLanguage = (event) => {
    const selectedLanguage = event.target.value
    setLanguage(selectedLanguage);
    setEnrollData((prevData) => ({
      ...prevData,
      language: selectedLanguage,
    }));

    const newErrors = {...errors};
    if(selectedLanguage){
      delete newErrors.language;
    }
    setErrors(newErrors);
  };

  const handleCourses = (event) => {
    const selectedCourses = event.target.value
    setCourses(selectedCourses);
    setEnrollData((prevData) => ({
      ...prevData,
      course: selectedCourses,
    }));

    const newErrors = {...errors};
    if(selectedCourses){
      delete newErrors.course;
    }
    setErrors(newErrors);
  }

  // validation
  const validate = async() => {
    const newErrors = {};
    // console.log(newErrors);
    if (!enrollData.name) newErrors.name = "Name is required";
    if (!enrollData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(enrollData.email)) newErrors.email = "Email is invalid";
    
    if (!enrollData.password) newErrors.password = "Password is required";
    if (!enrollData.phone) newErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(enrollData.phone)) newErrors.phone = "Phone number must contain only 10 digits";
    if (!role) newErrors.role = "Role is required";
    if (!language) newErrors.language = "Language is required";
    if (!course) newErrors.course = "Course is required";

    // console.log("validate error",newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      

      <Box sx={{ width: '100%' }} mt={15} mb={20}>
        <VuiBox component="form" role="form" onSubmit={handleEnroll}>
        {/* first col */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid  xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           
              Student Name
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput type="text" placeholder="Enter Name..." fontWeight="500"
            name='name'
            value={enrollData.name}
            onChange={handleChange}
            error={!!errors.name}
                    helperText={errors.name}
             />
          </GradientBorder>
          {errors.name && (
    <VuiTypography variant="caption" color="error" mt={1}>
      {errors.name}
    </VuiTypography>
  )}
        </VuiBox>
        </Grid>
{/* end */}

{/* second col */}
        <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
           Email
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="email"
              placeholder="Enter Email..."
              name='email'
              value={enrollData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          {errors.email && (
      <VuiTypography variant="caption" color="error" mt={1}>
        {errors.email}
      </VuiTypography>
    )}
          </VuiBox>
        </Grid>
       {/* end */}

       {/* third col */}

       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="Password"
              placeholder="Enter password..."
              name='password'
              value={enrollData.password}
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}

              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          <VuiTypography variant="caption" color="error" mt={1}>
      {errors.password}
    </VuiTypography>
          </VuiBox>
        </Grid>
       {/* end */}

       {/* fourth col */}
       <Grid xs={2} sm={4} md={4}>
  <VuiBox mb={1} ml={2}>
    <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
      Role
    </VuiTypography>
    <GradientBorder
      minWidth="100%"
      borderRadius={borders.borderRadius.lg}
      padding="1px"
      backgroundImage={radialGradient(
        palette.gradients.borderLight.main,
        palette.gradients.borderLight.state,
        palette.gradients.borderLight.angle
      )}
    >
      <Select
        sx={{
          backgroundColor: "#0f1535 !important",
          color: "white !important",
          borderRadius: "0.975rem !important",
          border: !!errors.role ? "1px solid red" : "none", // Highlight error
        }}
        minWidth="100%"
        value={role}
        onChange={handleRole}
      >
        <MenuItem value={"admin"}>Admin</MenuItem>
        <MenuItem value={"instructor"}>Instructor</MenuItem>
        <MenuItem value={"student"}>Student</MenuItem>
      </Select>
    </GradientBorder>
    {/* Display error message below the Select component */}
    {errors.role && (
      <VuiTypography variant="caption" color="error" mt={1}>
        {errors.role}
      </VuiTypography>
    )}
  </VuiBox>
</Grid>

       {/* end */}

       {/* fifth col */}
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Phone
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="number"
              placeholder="Enter PhoneNumber..."
              name="phone"
              value={enrollData.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>

          {errors.phone && (
      <VuiTypography variant="caption" color="error" mt={1}>
        {errors.phone}
      </VuiTypography>
    )}
          </VuiBox>
        </Grid>
       {/* end */}
        

       {/* sixth col */}
       <Grid xs={2} sm={4} md={4}>
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Language
            </VuiTypography>
            <GradientBorder 
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Category..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              
            /> */}
              <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={language}
          onChange={handleLanguage}
          error={!!errors.language}
          helperText={errors.language}
        >
          <MenuItem value={"Tamil"}>Tamil</MenuItem>
          <MenuItem value={"English"}>English</MenuItem>
          
        </Select>
           
          </GradientBorder>
          {errors.language && (
      <VuiTypography variant="caption" color="error" mt={1}>
        {errors.language}
      </VuiTypography>
    )}
          </VuiBox>
        </Grid>
       {/* end */}

       

       <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Course
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             {/* <VuiInput
              type="text"
              placeholder="Courses..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            /> */}
             {/* <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={course}
          onChange={handleCourses}
        >
          <MenuItem value={"Mern Stack"}>Mern Stack</MenuItem>
          <MenuItem value={"UI/UX"}>UI/UX</MenuItem>
          
        </Select> */}

        {/* map coursename */}
        <Select
                    name="course"
                    // value={courseName.courseName}
                    value={course}
                    onChange={handleCourses}
                    error={!!errors.courseName}
                    displayEmpty
                    fullWidth
                    sx={{
                        backgroundColor:"#0f1535 !important",
                        color:"white !important",
                        borderRadius:"0.975rem !important"
                      }}
                  >
                    <MenuItem value="" disabled>
                      Select Course 
                    </MenuItem>
                    {courseName.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
           
          </GradientBorder>
          {errors.course && (
      <VuiTypography variant="caption" color="error" mt={1}>
        {errors.course}
      </VuiTypography>
    )}
          </VuiBox>
        </Grid>

        {/* <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            RewardPoints
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             <VuiInput
              type="text"
              placeholder="rewardPoints..."
              name="rewardPoints"
              value={enrollData.rewardPoints}
              onChange={handleChange}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
          </VuiBox>
        </Grid> */}

        {/* <Grid xs={2} sm={4} md={4} >
        <VuiBox mb={1} ml={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            FreeLancer
            </VuiTypography>
            <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
             
            <Select 
              sx={{
                backgroundColor:"#0f1535 !important",
                color:"white !important",
                borderRadius:"0.975rem !important"
              }}

         minWidth="100%"
          value={freelancer}
          onChange={handleFreeLancer}
        >
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
        </Select>
          </GradientBorder>
          </VuiBox>
        </Grid> */}

        {/* submit */}

        
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
  <Grid item xs={12} sm={8} md={4}>
    <VuiBox mt={5} mb={1}>
      <VuiButton color="info" fullWidth type="submit">
        Submit
      </VuiButton>
    </VuiBox>
  </Grid>
</Grid>
</VuiBox>
    </Box>

    
     
      <Footer />
    </DashboardLayout>
  );
}

export default EnrollStudent;
