import * as React from "react";
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import "app.css";
import { Password, Upload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_Host } from "Api_utils";
import { addTask } from "../../redux/slices/TaskSlice";
import Swal from "sweetalert2";

function AddTask() {
  const dispatch = useDispatch();
  const history = useHistory();
  // validation state
  const [taskerror, setTaskError] = useState({});

  // enroll fields state
  const [taskData, setTaskData] = useState({
    taskName: "",
    taskType: "",
    status: "",
    fileData: "",
  });

  //   to retrive rewardtype state
  const [rewardType, setRewardType] = useState([]);

  React.useEffect(() => {
    const fetchRewardTypes = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-rewards`);
        setRewardType(response.data.data.map((reward) => reward.rewardType));
      } catch (error) {
        console.error("Error fetching reward types:", error);
      }
    };

    fetchRewardTypes();
  }, []);

  //   const handleTask = (e) => {
  //     e.preventDefault();
  //     axios.post(`${API_Host}/add-task`,taskData)
  //     .then(res => {
  //       dispatch(addTask(res.data))
  //       console.log("Reward added Successfully ",res.data);
  //       history.push('/task')
  //     })
  //     .catch(err =>console.log(err) )

  //   }

  const handleTask = async (e) => {
    e.preventDefault();

    const taskValid = taskValidate();
    if (!taskValid) {
      return;
    }

    const formData = new FormData();
    formData.append("taskName", taskData.taskName);
    formData.append("taskType", taskData.taskType);
    formData.append("status", taskData.status);

    // Append file only if it's selected
    if (taskData.fileData) {
      formData.append("file", taskData.fileData);
    }

    try {
      const response = await axios.post(`${API_Host}/add-task`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(addTask(response.data.data));
      console.log("Task added Successfully", response.data.data);
      Swal.fire({
        title: "Task Added Successfully!",
        text: "Your task has been added.",
        icon: "success",
        showClass: {
          popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
        },
        hideClass: {
          popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
        },
      });
      history.push("/task");
    } catch (err) {
      console.error("Error adding task:", err);
    }
  };

  //

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    setTaskData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value, // Store the file if it's a file input
    }));
    const error = { ...taskerror };
    if (value.trim() === "") {
      error[name] = ``;
    } else {
      delete error[name];
    }
    setTaskError(error);
  };

  const taskValidate = async () => {
    const error = {};
    console.log(error);
    if (!taskData.taskName) error.taskName = "TaskName is required";
    if (!taskData.taskType) error.taskType = "TaskType is required";
    if (!taskData.fileData) error.fileData = "uploadfile is required";
    if (!taskData.status) error.status = "status is required";

    setTaskError(error);
    return Object.keys(error).length === 0;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: "100%" }} mt={15} mb={38}>
        <VuiBox component="form" role="form" onSubmit={handleTask}>
          {/* first col */}
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid xs={2} sm={4} md={4}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  TaskName
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  {/* <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='rewardType'
            value={rewardsData.rewardType}
            onChange={handleChange}
             /> */}

                  <VuiInput
                    type="text"
                    placeholder="Enter Task..."
                    name="taskName"
                    value={addTask.taskName}
                    onChange={handleChange}
                    error={!!taskerror.taskName}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {taskerror.taskName && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskerror.taskName}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>
            {/* end */}

            {/* second col */}
            {/* <Grid xs={2} sm={4} md={4}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  TaskType
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  borderRadius={borders.borderRadius.lg}
                  padding="1px"
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <Select
                    name="taskType"
                    value={taskData.taskType}
                    onChange={handleChange}
                    error={!!taskerror.taskType}
                    displayEmpty
                    fullWidth
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Task Type
                    </MenuItem>
                    {rewardType.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </GradientBorder>
                {taskerror.taskType && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskerror.taskType}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid> */}
            {/* end */}

            <Grid xs={2} sm={4} md={4}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Upload File
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  borderRadius={borders.borderRadius.lg}
                  padding="1px"
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="file"
                    placeholder="uploadfile..."
                    name="fileData"
                    value={addTask.file}
                    onChange={handleChange}
                    error={!!taskerror.fileData}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {taskerror.fileData && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskerror.fileData}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>

            <Grid xs={2} sm={4} md={4}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Status
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  {/* <VuiInput type="text" placeholder="Enter Your Courses..." fontWeight="500"
            name='rewardType'
            value={rewardsData.rewardType}
            onChange={handleChange}
             /> */}

                  <VuiInput
                    type="text"
                    placeholder="Enter Status..."
                    name="status"
                    value={addTask.status}
                    onChange={handleChange}
                    error={!!taskerror.status}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {taskerror.status && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskerror.status}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
          >
            <Grid item xs={12} sm={8} md={4}>
              <VuiBox mt={5} mb={1}>
                <VuiButton color="info" fullWidth type="submit">
                  Submit
                </VuiButton>
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default AddTask;
