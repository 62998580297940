import * as React from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { Box, Radio, RadioGroup, FormControlLabel, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { API_Host } from "Api_utils";
import data from "layouts/dashboard/components/Projects/data";

function SubmitQuiz() {
  const { id } = useParams();
  const history = useHistory();

  const [quiz, setQuiz] = useState({
    topicName: "",
    questions: [{ question: "", options: [""], correctAnswer: "" }],
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [userAnswers, setUserAnswers] = useState({});
  const [result, setResult] = useState(null);

  // Fetch quiz data from server
  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(`${API_Host}/get-particular-quiz/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuiz(response.data.data);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      }
    };
    fetchQuiz();
  }, [id]);

  const currentQuestion = quiz.questions[currentQuestionIndex];
  console.log(quiz);

  // Pagination handlers
  const handleNextQuestion = () => {
    if(!selectedAnswer){
      Swal.fire("Please select an option", "You must choose an answer before proceeding.", "warning");
      return;
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedAnswer("");
    setResult(null);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedAnswer("");
    setResult(null);
  };

  // Store selected answer for the current question
  const handleAnswerChange = (event) => {
    const answer = event.target.value;
    setSelectedAnswer(answer);

    // Update the user's answers for the current question
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionIndex]: answer,
    }));
  };

  // Handle the submission of the last question
  const handleSubmit = async() => {
    if (!selectedAnswer) {
      Swal.fire("Please select an option", "You must choose an answer before submitting.", "warning");
      return;
    }
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");

    // Compare user answers with correct answers
    const score = quiz.questions.reduce((totalScore, question, index) => {
      const userAnswer = userAnswers[index];
      const isCorrect = userAnswer === question.correctAnswer;
      const inCorrect = userAnswer !== question.correctAnswer;
      console.log(question);
      console.log(inCorrect);

      console.log(`Question ${index + 1}: ${isCorrect ? "Correct" : "Wrong"}`);
      if(isCorrect){
            // Prepare submission data to send to the backend
            const answeredBy = {
              quizId: question._id,
              user: email,
              selectedOption: userAnswer,  // Send selected option
              correctAnswer: question.correctAnswer, // Send correct answer for comparison
            };

           axios.put(`${API_Host}/edit-quiz/${id}`, answeredBy, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
              answeredBy: email,
            },
          }).then((data)=>{
            history.push(`/quiz-result/${id}`) 
          Swal.fire("Quiz Submitted!", "Your answers have been saved.", "success");

          }).catch((err)=>{
                      // history.push("/quiz-results");
          console.log("Error submitting quiz:", err);
          Swal.fire("Error", "There was an issue submitting your quiz.", "error");
          })

        }
        else{
            // Prepare submission data to send to the backend
            // Prepare submission data to send to the backend
            const incorrectansweredBy = {
              quizId: question._id,
              user: email,
              selectedOption: userAnswer,  // Send selected option
              correctAnswer: question.correctAnswer, // Send correct answer for comparison
            };
           axios.put(`${API_Host}/edit-quiz/${id}`,incorrectansweredBy , {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
              answeredBy: email,
            },
          }).then((data)=>{
            history.push(`/quiz-result/${id}`) 
          Swal.fire("Quiz Submitted!", "Your answers have been saved.", "success");

          }).catch((err)=>{
                      // history.push("/quiz-results");
          console.log("Error submitting quiz:", err);
          Swal.fire("Error", "There was an issue submitting your quiz.", "error");
          })
        }
        return totalScore + (isCorrect ? 1 : 0);
      }, 0);
     
    console.log("Final Score:", score);
    const reqData = {rewardPoints: score};
    console.log(reqData);
    try {
      await axios.put( `${API_Host}/reward-edit-user/${email}`,reqData,
     
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
    } catch (error) {
      console.error("Error updating reward points:", error);
   
    }


  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%" }} mt={10} mb={34}>
        <VuiBox>
          <Card>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid xs={12} sm={12} md={12}>
                <VuiBox mb={1} ml={2} mt={6}>
                  <VuiTypography component="label" variant="button" color="white" fontWeight="medium" sx={{ fontSize: "20px", wordSpacing: "3px" }}>
                    {currentQuestion.question}
                  </VuiTypography>
                </VuiBox>
              </Grid>

              {/* Radio Buttons for Options */}
              <RadioGroup value={selectedAnswer} onChange={handleAnswerChange} >
                {currentQuestion.options.map((option, index) => (
                  <FormControlLabel 
                    sx={{ paddingLeft: "20px" }}
                    key={index}
                    value={option}
                    control={<Radio style={{backgroundColor:'blue'}}/>}
                    label={<span style={{ color: "white", fontSize: "20px" }}>{option}</span>}
                  />
                ))}
              </RadioGroup>
            </Grid>

            <Grid container justifyContent="space-between" mt={4}>
              <VuiButton color="info" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                Previous
              </VuiButton>

              {currentQuestionIndex === quiz.questions.length - 1 ? (
                <VuiButton color="info" onClick={handleSubmit}>
                  Submit Answer
                </VuiButton>
              ) : (
                <VuiButton color="info" onClick={handleNextQuestion} disabled={currentQuestionIndex === quiz.questions.length - 1}>
                  Next
                </VuiButton>
              )}
            </Grid>

            {result && (
              <VuiTypography
                variant="h6"
                style={{
                  color: result === "Correct!" ? "green" : "red",
                  paddingLeft: "40px",
                }}
                mt={2}
              >
                {result}
              </VuiTypography>
            )}
          </Card>
        </VuiBox>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default SubmitQuiz;
