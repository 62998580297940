// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";

// Images
import AdobeXD from "examples/Icons/AdobeXD";
import Atlassian from "examples/Icons/Atlassian";
import Slack from "examples/Icons/Slack";
import Spotify from "examples/Icons/Spotify";
import Jira from "examples/Icons/Jira";
import Invision from "examples/Icons/Invision";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import { useSelector } from "react-redux";
import { getEnrollStudent } from '../../../../../redux/slices/EnrollSlice';
import { useEffect } from "react";

export default function data() {

  const student = useSelector((state) => state.enrollStudent.enrollStudent);

  const instructor = async () => {
    try {
      const role = "instructor";
      const response = await axios.get(`${API_Host}/get-user-role/${role}`);
      dispatch(getEnrollStudent(response.data.data));
      console.log("data fetched successfully", response.data.data);
    } catch (err) {
      console.log(err);
    }
    useEffect(() => {
      instructor();
    }, []);
  };

 

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  return {
    columns: [
      { name: "Name", align: "left" },
      { name: "Course", align: "center" },
      { name: "Language", align: "center" },
      // { name: "completion", align: "center" },
    ],

    rows: student.map((student) => ({
      Name: (
        <VuiBox display="flex" alignItems="center">
          <VuiAvatar src={avatar1} alt={student.name} size="sm" />
          <VuiTypography variant="button" fontWeight="medium" ml={2} color='white'>
            {student.name}
          </VuiTypography>
        </VuiBox>
      ),
      Course: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {student.course}
        </VuiTypography>
      ),
      Language: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {student.language}
        </VuiTypography>
      ),
    })),
  };
}
