import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, Card, Grid, Typography, Avatar, Button } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EditIcon from "@mui/icons-material/Edit";
import { API_Host } from "Api_utils";
import { getEnrollStudent } from "../../redux/slices/EnrollSlice";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import Swal from "sweetalert2";
function Profile() {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.enrollStudent.enrollStudent);

  const [isEditingGithubUrl, setIsEditingGithubUrl] = useState(false);
  const [isEditingProfileImage, setIsEditingProfileImage] = useState(false);
  const [editedGithubUrl, setEditedGithubUrl] = useState("");
  const [editedProfileImage, setEditedProfileImage] = useState(null);
  const [githubUrlErrorMessage, setGithubUrlErrorMessage] = useState("");
  const [profileImageErrorMessage, setProfileImageErrorMessage] = useState("");

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(`${API_Host}/get-user-role/student`);
      dispatch(getEnrollStudent(response.data.data));
      console.log("Data fetched successfully:", response.data.data);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  useEffect(() => {
    fetchStudentData();
  }, [dispatch]);

  const loggedInEmail = localStorage.getItem("email");
  const student = students.find((student) => student.email === loggedInEmail);

  const handleEditGithubClick = () => {
    setIsEditingGithubUrl(true);
    setEditedGithubUrl(student.githubUrl || "");
  };

  const handleEditImageClick = () => {
    setIsEditingProfileImage(true);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setErrorMessage("");

  //   // Validate GitHub URL
  //   const githubUrlPattern = /^(https?:\/\/)?(www\.)?(github\.com\/)[a-zA-Z0-9_-]+\/?[a-zA-Z0-9_-]*$/;
  //   if (isEditingGithubUrl && !githubUrlPattern.test(editedGithubUrl)) {
  //       setErrorMessage("Please enter a valid GitHub URL.");
  //       return; // Exit early if the URL is invalid
  //   }

  //   const formData = new FormData();

  //   // If updating GitHub URL
  //   if (isEditingGithubUrl) {
  //     formData.append("githubUrl", editedGithubUrl);
  //   }

  //   // If updating profile image
  //   if (editedProfileImage) {
  //     formData.append("file", editedProfileImage);
  //   }

  //   try {
  //     const response = await axios.put(`${API_Host}/edit-user-profile/${student.id}`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     console.log("Profile updated successfully:", response.data);

  //     fetchStudentData();

  //       // Resetting editing states
  //       setIsEditingGithubUrl(false);
  //       setIsEditingProfileImage(false);
  //       setEditedGithubUrl(""); // Reset the edited GitHub URL state
  //       setEditedProfileImage(null); // Reset the edited profile image state
  //     Swal.fire({
  //       title: "Student  profile Updated Successfully!",
  //       text: "Student profile has been updated.",
  //       icon: "success",
  //       showClass: {
  //         popup: `
  //           animate__animated
  //           animate__fadeInUp
  //           animate__faster
  //         `
  //       },
  //       hideClass: {
  //         popup: `
  //           animate__animated
  //           animate__fadeOutDown
  //           animate__faster
  //         `
  //       }
  //     });
  //   } catch (err) {
  //     console.error("Error updating profile:", err);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error message
    setGithubUrlErrorMessage("");
    setProfileImageErrorMessage("");

    if (isEditingProfileImage && !editedProfileImage) {
      setProfileImageErrorMessage("Please select a profile image.");
      return;
    }

    if (isEditingGithubUrl && !editedGithubUrl.trim()) {
      setGithubUrlErrorMessage("Please provide link.");
      return;
    }
   

    const formData = new FormData();

    // If updating GitHub URL
    if (isEditingGithubUrl) {
      formData.append("githubUrl", editedGithubUrl);
    }

    // If updating profile image
    if (editedProfileImage) {
      formData.append("file", editedProfileImage);
    }

    try {
      const response = await axios.put(`${API_Host}/edit-user-profile/${student.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Profile updated successfully:", response.data);
      fetchStudentData();
      // Resetting editing states
      setIsEditingGithubUrl(false);
      setIsEditingProfileImage(false);
      setEditedGithubUrl(""); // Reset the edited GitHub URL state
      setEditedProfileImage(null); // Reset the edited profile image state

      Swal.fire({
        title: "Student profile Updated Successfully!",
        text: "Student profile has been updated.",
        icon: "success",
        showClass: {
          popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                `,
        },
        hideClass: {
          popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                `,
        },
      });
    } catch (err) {
      console.error("Error updating profile:", err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={40}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} columns={12}>
            {student ? (
              <>
                <Grid item xs={12} md={6} key={student.id}>
                  <VuiBox mb={2} ml={2}>
                    <VuiTypography variant="button" color="white" fontWeight="medium">
                      Profile
                    </VuiTypography>
                    <Card>
                      <Box display="flex" alignItems="center" px={2} py={1}>
                        <Avatar
                          src={
                            student.profile?.fileName
                              ? `http://portal1.appblocky.com/uploads-profile/${student.profile.fileName}`
                              : ""
                          }
                          // alt="Profile Image"
                          sx={{ width: 56, height: 56 }}
                        />
                        <EditIcon
                          fontSize="small"
                          sx={{ ml: "auto", mb: 3 }}
                          className="editIcon"
                          onClick={handleEditImageClick}
                        />
                      </Box>
                      {isEditingProfileImage && (
                        <>
                          <VuiInput
                            type="file"
                            name="profile"
                            onChange={(e) => setEditedProfileImage(e.target.files[0])}
                            sx={{ flex: 1, marginRight: 1, mb: 2 }}
                          />
                           {profileImageErrorMessage && (
                            <Typography sx={{ color: "red", ml: 2, mb: 1 }}>
                              {profileImageErrorMessage}
                            </Typography>
                          )}

                          <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                              <VuiButton color="info" fullWidth type="submit">
                                Submit
                              </VuiButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Card>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <VuiBox mb={2} ml={2}>
                    <VuiTypography variant="button" color="white" fontWeight="medium">
                      GitHub URL
                    </VuiTypography>
                    <Card sx={{ mt: 1 }}>
                      <Box display="flex" alignItems="center" px={2} py={1}>
                        <Typography sx={{ color: "white !important" }}>
                          {" "}
                          {student.githubUrl || "Not provided"}
                        </Typography>
                        <EditIcon
                          fontSize="small"
                          sx={{ ml: "auto", mb: 2 }}
                          className="editIcon"
                          onClick={handleEditGithubClick}
                        />
                      </Box>
                      {isEditingGithubUrl && (
                        <>
                          <VuiInput
                            value={editedGithubUrl}
                            name="githubUrl"
                            onChange={(e) => setEditedGithubUrl(e.target.value)}
                            placeholder="Enter your GitHub URL"
                            sx={{ flex: 1, marginRight: 1,mb:2 }}
                          />
                           {githubUrlErrorMessage && (
                            <Typography sx={{ color: "red", ml: 2, mb: 1 }}>
                              {githubUrlErrorMessage}
                            </Typography>
                          )}
                          <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                              <VuiButton color="info" fullWidth type="submit">
                                Submit
                              </VuiButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                     
                    </Card>
                  </VuiBox>
                </Grid>
              </>
            ) : (
              <Typography sx={{ color: "white !important", ml: 2 }}>
                No student data available.
              </Typography>
            )}
          </Grid>
         
          {/* <Card sx={{ mt: 3, mb: 5 }}>
            <div>
              <Typography sx={{ color: "white !important" }}>Reward Points:</Typography>
              <Box display="flex" alignItems="center">
                <Typography sx={{ color: "white !important", marginRight: 1 }}>
                  {student.rewardPoints || 0}
                </Typography>
                <EmojiEventsIcon sx={{ color: "yellow", fontSize: "30px" }} />
              </Box>
            </div>
          </Card> */}
          {/* <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <VuiButton color="info" fullWidth type="submit">
                Submit
              </VuiButton>
            </Grid>
          </Grid> */}
        </form>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
